import React from "react";
import Typography from "@material-ui/core/Typography";
import { LABOR, SALES, INVENTORY_CONTROL, SERVICES, OMNI_CHANNEL } from "../../common/constants";
import "./styles.scss";

const SummaryCard = ({
  title,
  children,
  primaryLocationName,
  selectedDateMatrix,
  handleLaborCardClick,
  handleSalesCardClick,
  handleInventoryControlCardClick,
  handleOmniChannelCardClick,
  handleServicesCardClick,
}) => {
  const handleClick = () => {
    switch (title) {
      case SALES:
        handleSalesCardClick();
        break;
      case SERVICES:
          handleServicesCardClick();
          break;
      case OMNI_CHANNEL:
          handleOmniChannelCardClick();
          break;    
      case LABOR:
        handleLaborCardClick();
        break;
      case INVENTORY_CONTROL:
        handleInventoryControlCardClick();
        break;
      default:
        break;
    }
  };

  return (
    <div data-testid="summary-card" className="summary-card">
      <Typography
        color="primary"
        component="h6"
        variant="h6"
        className="title-row"
        onClick={handleClick}
        style={{
          textDecoration: "underline",
          width: "fit-content",
          textUnderlinePosition: "under",
          cursor: 'pointer',
          // cursor: title === OMNI_CHANNEL ? 'default' : 'pointer', commented by ars
        }}
      >
        {title}
      </Typography>

      {}
      {children}
    </div>
  );
};

export default SummaryCard;
