/* eslint-disable no-unused-vars */
import React from "react";
import InventoryControlDriversTab from "../InventoryControlDetailsTabs";
import InventoryMerchDetailTab from "../InventoryControlMerch/InventoryMerchDetailTab"
import TabManagerGeneric from "../TabManagerGeneric/TabManagerGeneric";
import { DRIVERS, MERCH } from "../../common/constants";

const InventoryControlTabManager = () => {
  const salesTabs = [
    {
      tabTitle: DRIVERS,
      component: InventoryControlDriversTab,
      isActive: true,
      tabId: "drivers"
    },
    {
    	tabTitle: MERCH,
    	component: InventoryMerchDetailTab,
    	isActive: false,
      tabId: "merch"
    },
  ];
  return (
    <>
      <TabManagerGeneric tabsData={salesTabs} mainPathName='inventory-details' />
    </>
  );
};

export default InventoryControlTabManager;
