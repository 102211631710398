import React from "react";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core//Breadcrumbs";
import Link from "@material-ui/core//Link";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import "./styles.scss";
const BreadCrumbs = (props) => {
  const { onBreadCrumbVisibilityChange } = props;
  const handleClick = (event) => {
    event.preventDefault();
    onBreadCrumbVisibilityChange(false);
  };
  return (
    <div role="presentation" className="outer-class">
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small" />}
      >
        <Link underline="always" href="#" color="initial">
          <Typography
            color="initial"
            className="primary-text"
            onClick={handleClick}
            datarowselected={props.dataRowSelected}
          >
            {props.PrimaryValue}
          </Typography>
        </Link>
        <Typography className="secondary-text">
          {props.SelectedChildRow}
        </Typography>
      </Breadcrumbs>
    </div>
  );
};

export default BreadCrumbs;
