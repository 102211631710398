import React from "react";

const DataRow = ({
  title,
  unitQuantity,
  itemCount,
  adjustmentsAmount,
  isFirstRow,
  handleDataRowClick,
}) => {
  const handleTitleClick = () => {
    handleDataRowClick(title);
  };
  return (
    <>
      <div className="flex row">
        <div
          className="driver-title"
          style={isFirstRow ? { color: "#0066ff" } : { color: "000000" }}
        >
          {isFirstRow ? (
            <u onClick={handleTitleClick}>{title} </u>
          ) : (
            <>{title} </>
          )}
        </div>
      </div>
      <div className="flex row">
        <div className="b-1-3 driver-sales-container">
          <div className="driver-sales-value">{itemCount}</div>
          <div className="driver-sales-label"># of SKUs</div>
        </div>
        <div className="b-1-3 driver-sales-container">
          <div className="driver-sales-value">{unitQuantity}</div>
          <div className="driver-sales-label">Units</div>
        </div>
        <div className="b-1-3 driver-sales-container">
          <div className="driver-sales-value">{adjustmentsAmount}</div>
          <div className="driver-sales-label">$</div>
        </div>
      </div>
      <div className={`border-row top-border-row`} />
    </>
  );
};

export default DataRow;
