import React from "react";
import SalesDetailTab from "../SalesDetailTab";
import MerchDetailTab from "../MerchDetailTab/MerchDetailTab";
import SalesTrend from "../SalesTrend";
import TabManagerGeneric from "../TabManagerGeneric/TabManagerGeneric";
import { DRIVERS, MERCH, TREND, ZERO_SALES } from "../../common/constants";
import InventoryMerchDetailTab from "../InventoryControlMerch/InventoryMerchDetailTab";

const SalesTabManager = () => {
	const salesTabs = [
		{
			tabTitle: DRIVERS,
			component: SalesDetailTab,
			isActive: true,
			tabId: "drivers",
		},
		{
			tabTitle: TREND,
			component: SalesTrend,
			isActive: false,
			tabId: "trend",
		},
		{
			tabTitle: MERCH,
			component: MerchDetailTab,
			isActive: false,
			tabId: "merch",
		},
		{
			tabTitle: ZERO_SALES,
			component: InventoryMerchDetailTab,
			isActive: false,
			tabId: "zero-sales",
		},
	];
	return (
		<>
			<TabManagerGeneric tabsData={salesTabs} mainPathName='sales-details' />
		</>
	);
};

export default SalesTabManager;
