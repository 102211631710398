import React, { useEffect, useState } from "react";
import "./styles.scss";
import { useLazyQuery } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";
import {
  GET_DEFAULT_LOCATION,
  GET_MERCH_DATA,
  GET_INVENTORY_CONTROL_SKU_SUMMARY,
  GET_INVENTORY_CONTROL_DEPARTMENT_SUMMARY,
  GET_INVENTORY_CONTROL_DRIVER_TAB_DETAIL,
} from "../../graphql/queries";
import SelectDropdown from "../SelectDropdown";
import DataRow from "../InventoryControlDetailsTabs/DataRow";
import {
  ALL_DEPARTMENTS,
  DATA_FETCH_ERROR,
  STORE_TOTAL,
  ONLOAN_INVALID_STOREID_ERROR,
  NO_CONNECTION_ERROR,
  INVENTORY_CONTROL_TOTAL_ADJUSTMENTS_SORT_OPTIONS,
  INVENTORY_CONTROL_TOTAL_ADJUSTMENTS_SORT_DROPDOWN_ARRAY,
  ALL_ADJUSTMENTS,
  SHORT_MARKED_OUT_OF_STOCK,
} from "../../common/constants";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import {
  getDate,
  toTitleCase,
  getStoreChecker,
  toFixedVal,
  roundValue,
  MARKED_OUT_OF_STOCK_OPTIONS,
} from "../../utils/utils";
import ErrorMessage from "../ErrorMessage";
import ICAdjustmentSkuRow from "../InventoryControlDriversMenus/ICAdjustmentSkuRow";
import useNetwork from "../UseNetwork";
import SkuSortableMetrice from "../InventoryControlDetailsTabs/SkuSortableMetrice";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: "1.6rem",
    color: state.isSelected ? "#FFFFFF" : "#353535",
    padding: 7,
  }),
};

const MarkedOutOfStock = ({ employeeInfo = {}, dateMatrix }) => {
  const networkStatus = useNetwork();
  const [storeMerchs, setStoreMerchs] = React.useState([]);
  const [selectedMerch, setSelectedMerch] = React.useState({});
  const [merchsData, setMerchsData] = React.useState([]);
  const [expandSkuData, setExpandSkuData] = React.useState(true);
  // eslint-disable-next-line no-unused-vars
  const [sortBy, setSortBy] = React.useState("Sales");
  // eslint-disable-next-line no-unused-vars
  const [adjustmentDropdownOptions, setAdjustmentDropdowOptions] =
    React.useState(MARKED_OUT_OF_STOCK_OPTIONS);
  const [
    selectedAdjustmentDropdownOption,
    setSelectedAdjustmentDropdownOption,
  ] = React.useState([adjustmentDropdownOptions[0]]);
  const [selectedAdjustmentCodeValue, setSelectedAdjustmentCodeValue] =
    React.useState("");

  const [pageSort, setPageSort] = useState("desc");
  const [pageOrder, setPageOrder] = useState("units");

  const [selectedMerchNumber, setSelectedMerchNumber] = React.useState("");
  const [selectedLocation, setSelectedLocation] = React.useState(
    JSON.parse(sessionStorage.getItem("selectedLocation"))
  );
  const userEmail = employeeInfo?.adUpn;
  let selectedStoreId = localStorage.getItem("storeId");
  const activeStorechecker = getStoreChecker(selectedStoreId);
  
  const [getDefaultLocation, { error: locationError = {} }] = useLazyQuery(
    GET_DEFAULT_LOCATION,
    {
      context: {
        headers: {
          Authorization: localStorage.getItem("jwtToken"),
        },
      },
      onCompleted: (data) => {
        let { salesSummaryV2 } = data;
        if (selectedLocation.locationName === "NA") {
          setSelectedLocation({
            locationNo: salesSummaryV2?.location?.primaryLocationNumber,
            locationName: salesSummaryV2?.location?.primaryLocationName,
          });
          sessionStorage.setItem(
            "selectedLocation",
            JSON.stringify({
              locationNo: salesSummaryV2?.location?.primaryLocationNumber,
              locationName: salesSummaryV2?.location?.primaryLocationName,
            })
          );
        }
      },
    }
  );

  useEffect(() => {
    if (userEmail && (activeStorechecker === true || selectedStoreId === "")) {
      getDefaultLocation({
        variables: { request: { email: userEmail, storeId: selectedStoreId } },
      });
    }
  }, [userEmail, getDefaultLocation, selectedStoreId, activeStorechecker]);

  const [
    getMerchSummary,
    {
      data: {
        departmentSummary: {
          departmentSalesReport = [],
          //   departmentTotalSalesReport: { compPercent, sales, units } = {},
        } = {},
      } = {},
      error = {},
      loading,
    },
  ] = useLazyQuery(GET_MERCH_DATA, {
    context: {
      headers: {
        Authorization: localStorage.getItem("jwtToken"),
      },
    },
  });

  useEffect(() => {
      if (departmentSalesReport?.length > 0) {
        let newOptions = [
          //{ label: STORE_TOTAL, value: STORE_TOTAL },
          { label: ALL_DEPARTMENTS, value: ALL_DEPARTMENTS },
        ];
        
        setMerchsData(departmentSalesReport);
        departmentSalesReport.forEach((obj) => {
          if (obj["department"]) {
            let option = {};
            option.label =
              obj["department"]["deptName"] === "HBA"
                ? obj["department"]["deptNumber"] +
                  ": " +
                  obj["department"]["deptName"]
                : obj["department"]["deptNumber"] +
                  ": " +
                  toTitleCase(obj["department"]["deptName"]);
            option.value = obj["department"]["deptNumber"];
            newOptions.push(option);
          }
        });
        setStoreMerchs(newOptions);
        setSelectedMerch(newOptions[0]);
        setExpandSkuData(false);
        setSelectedMerchNumber("");
        setSelectedAdjustmentDropdownOption(adjustmentDropdownOptions[0]);
        setSelectedAdjustmentCodeValue("");
    }
  }, [departmentSalesReport, adjustmentDropdownOptions]);

  useEffect(() => {
    if (
      userEmail &&
      selectedLocation &&
      selectedLocation?.locationName !== "NA" &&
      dateMatrix
    ) {
      getMerchSummary({
        variables: {
          request: {
            email: userEmail,
            location: {
              primaryLocationNumber: selectedLocation?.locationNo,
              primaryLocationName: selectedLocation?.locationName,
            },
            toDate: getDate("YESTERDAY", ""),
            matrixType: dateMatrix,
            pageableSort: {
              sort: pageSort,
              orderBy: sortBy,
            },
          },
        },
      });
    }
  }, [
    userEmail,
    selectedLocation,
    dateMatrix,
    getMerchSummary,
    pageSort,
    sortBy,
  ]);

  const handleOnStoreMerchsChange = (option) => {
    let filteredMerchsData = [];
    if (![ALL_DEPARTMENTS, STORE_TOTAL].includes(option.label)) {
      let tempData = [];
      tempData = merchsData.filter(
        (obj) => obj.department.deptNumber === option.value
      );
      if (
        tempData.length > 0 &&
        (tempData[0].sales > 0 ||
          tempData[0].units > 0 ||
          tempData[0].mix > 0 ||
          tempData[0].compPercent > 0)
      ) {
        filteredMerchsData = tempData;
        setSelectedMerchNumber(filteredMerchsData[0].department.deptNumber);
      }
    } else if (option.label === STORE_TOTAL) {
      setSelectedMerchNumber("");
    } else {
      filteredMerchsData = merchsData;
      setSelectedMerchNumber("");
    }
    setSelectedMerch(option);
    setSelectedAdjustmentDropdownOption(adjustmentDropdownOptions[0]);
    setSelectedAdjustmentCodeValue("");
  };

  const handleOnAdjustmentDropdownChange = (option) => {
    let filteredAdjustmentDropdownData = [];
    if (![ALL_ADJUSTMENTS].includes(option.label)) {
      let tempData = [];
      tempData = adjustmentDropdownOptions.filter(
        (obj) => obj.value === option.value
      );
      if (tempData.length > 0) {
        filteredAdjustmentDropdownData = tempData;
        setSelectedAdjustmentCodeValue(filteredAdjustmentDropdownData[0].value);
      }
    } else {
      setSelectedAdjustmentCodeValue("");
    }
    setSelectedAdjustmentDropdownOption(option);
  };
  
  const [
    getInventoryControlDriversTabDetails,
    {
      data: { inventoryControl: { invControlDrivers } = {} } = {},
      driverloading,
      // eslint-disable-next-line no-unused-vars
      drivererror,
    },
  ] = useLazyQuery(GET_INVENTORY_CONTROL_DRIVER_TAB_DETAIL, {
    context: {
      headers: {
        Authorization: localStorage.getItem("jwtToken"),
      },
    },
  });

  useEffect(() => {
    if (
      userEmail &&
      dateMatrix &&
      selectedLocation &&
      selectedLocation?.locationName !== "NA"
    ) {
      getInventoryControlDriversTabDetails({
        variables: {
          request: {
            email: userEmail,
            location: selectedLocation?.locationName,
            toDate: getDate("YESTERDAY", ""),
            matrixType: dateMatrix,
          },
        },
      });
    }
  }, [
    userEmail,
    dateMatrix,
    getInventoryControlDriversTabDetails,
    selectedLocation,
  ]);

  const [
    getInventoryControlDepartmentReport,
    {
      data: {
        inventoryControlDepartmentSummary: {
          inventoryControlDepartmentTotalReport,
        } = {},
      } = {},
      deptloading,
      // eslint-disable-next-line no-unused-vars
      depterror = {},
    },
  ] = useLazyQuery(GET_INVENTORY_CONTROL_DEPARTMENT_SUMMARY, {
    context: {
      headers: {
        Authorization: localStorage.getItem("jwtToken"),
      },
    },
  });

  useEffect(() => {
    if (
      userEmail &&
      dateMatrix &&
      selectedLocation &&
      selectedLocation?.locationName !== "NA"
    ) {
      getInventoryControlDepartmentReport({
        variables: {
          request:
          selectedMerchNumber === "" && selectedAdjustmentCodeValue !== ""
          ?{
            email: userEmail,
            location: {
              primaryLocationNumber: selectedLocation?.locationNo,
              primaryLocationName: selectedLocation?.locationName,
            },
            toDate: getDate("YESTERDAY", ""),
            matrixType: dateMatrix,
            adjustmentCategory: SHORT_MARKED_OUT_OF_STOCK,
            adjustmentDescription:selectedAdjustmentCodeValue,
          } 
          :selectedMerchNumber !== "" && selectedAdjustmentCodeValue === ""
          ?{
            email: userEmail,
            location: {
              primaryLocationNumber: selectedLocation?.locationNo,
              primaryLocationName: selectedLocation?.locationName,
            },
            toDate: getDate("YESTERDAY", ""),
            matrixType: dateMatrix,
            adjustmentCategory: SHORT_MARKED_OUT_OF_STOCK,
            departmentNumber: selectedMerchNumber,
          }
          : selectedMerchNumber !== "" && selectedAdjustmentCodeValue !== ""
          ?{
            email: userEmail,
            location: {
              primaryLocationNumber: selectedLocation?.locationNo,
              primaryLocationName: selectedLocation?.locationName,
            },
            toDate: getDate("YESTERDAY", ""),
            matrixType: dateMatrix,
            adjustmentCategory: SHORT_MARKED_OUT_OF_STOCK,
            departmentNumber: selectedMerchNumber,
            adjustmentDescription:selectedAdjustmentCodeValue,
          }
          :{
            email: userEmail,
            location: {
              primaryLocationNumber: selectedLocation?.locationNo,
              primaryLocationName: selectedLocation?.locationName,
            },
            toDate: getDate("YESTERDAY", ""),
            matrixType: dateMatrix,
            adjustmentCategory: SHORT_MARKED_OUT_OF_STOCK,
          }
        },
      });
    }
  }, [
    userEmail,
    dateMatrix,
    getInventoryControlDepartmentReport,
    selectedLocation,
    selectedMerchNumber,
    selectedAdjustmentCodeValue,
  ]);

  const [
    getInventoryControlSkuData,
    {
      data: {
        inventoryControlSkuSummary: { inventoryControlSkuSalesReport } = {},
      } = {},
      skuError,
      skuLoading,
    },
  ] = useLazyQuery(GET_INVENTORY_CONTROL_SKU_SUMMARY, {
    context: {
      headers: {
        Authorization: localStorage.getItem("jwtToken"),
      },
    },
  });

  useEffect(() => {
    if (
      userEmail &&
      selectedLocation &&
      selectedLocation?.locationName !== "NA" &&
      dateMatrix &&
      pageSort &&
      pageOrder
    ) {
      getInventoryControlSkuData({
        variables: {
          request:
          selectedMerchNumber === "" && selectedAdjustmentCodeValue !== ""
          ?{
            email: userEmail,
            location: {
              primaryLocationNumber: selectedLocation?.locationNo,
              primaryLocationName: selectedLocation?.locationName,
            },
            toDate: getDate("YESTERDAY", ""),
            matrixType: dateMatrix,
            adjustmentCategory: SHORT_MARKED_OUT_OF_STOCK,
            adjustmentDescription:selectedAdjustmentCodeValue,
            pageableSort: {
              sort: pageSort,
              orderBy: pageOrder,
            },
          }
          :selectedMerchNumber !== "" && selectedAdjustmentCodeValue === ""
          ? {
              email: userEmail,
              location: {
                primaryLocationNumber: selectedLocation?.locationNo,
                primaryLocationName: selectedLocation?.locationName,
              },
              toDate: getDate("YESTERDAY", ""),
              matrixType: dateMatrix,
              adjustmentCategory: SHORT_MARKED_OUT_OF_STOCK,
              departmentNumber: selectedMerchNumber,
              pageableSort: {
                sort: pageSort,
                orderBy: pageOrder,
              },
            }
            : selectedMerchNumber !== "" && selectedAdjustmentCodeValue !== "" ?
            {
              email: userEmail,
              location: {
                primaryLocationNumber: selectedLocation?.locationNo,
                primaryLocationName: selectedLocation?.locationName,
              },
              toDate: getDate("YESTERDAY", ""),
              matrixType: dateMatrix,
              adjustmentCategory: SHORT_MARKED_OUT_OF_STOCK,
              departmentNumber: selectedMerchNumber,
              adjustmentDescription: selectedAdjustmentCodeValue,
              pageableSort: {
                sort: pageSort,
                orderBy: pageOrder,
              },
            }
            : {
                email: userEmail,
                location: {
                  primaryLocationNumber: selectedLocation?.locationNo,
                  primaryLocationName: selectedLocation?.locationName,
                },
                toDate: getDate("YESTERDAY", ""),
                matrixType: dateMatrix,
                adjustmentCategory: SHORT_MARKED_OUT_OF_STOCK,
                pageableSort: {
                  sort: pageSort,
                  orderBy: pageOrder,
                },
              },
        },
      });
    }
  }, [
    userEmail,
    selectedLocation,
    dateMatrix,
    getInventoryControlSkuData,
    selectedMerchNumber,
    selectedAdjustmentCodeValue,
    pageSort,
    pageOrder,
  ]);

  function onApplyClickHandler(selectedOptions) {
    const { value, orderBy } = selectedOptions[0];
    setPageSort(value);
    setPageOrder(orderBy);
  }
  
  return (
    <div className="detail-tab">
      {!networkStatus && <ErrorMessage message={NO_CONNECTION_ERROR} />}
      {(loading || deptloading || skuLoading) && (
        <div className="total-adj-detail-loader">
          <CircularProgress color="primary" size={30} />
        </div>
      )}
      {!loading &&(
        <>
          {(error.message) ? (
            <ErrorMessage
              statusCode={
                error?.networkError?.statusCode ||
                locationError?.networkError?.statusCode
              }
              message={DATA_FETCH_ERROR}
            />
          ) : null}
          {activeStorechecker === false && selectedStoreId ? (
            <ErrorMessage message={ONLOAN_INVALID_STOREID_ERROR} />
          ) : null}
          { !departmentSalesReport?.length > 0 ? (
            <div className="noData-card">
              <div className="no-data-row">
                <Typography component="h1" variant="h1" className="label">
                  There is no data available for this timeframe. Please go back
                  and try a new selection{" "}
                </Typography>
              </div>
            </div>
          ) : (
            <>
              <div className="dropdown-container">
                <div className="dropdown-column">
                  <SelectDropdown
                    className="dropdown-row"
                    styles={customStyles}
                    onChange={handleOnStoreMerchsChange}
                    options={storeMerchs}
                    value={selectedMerch}
                    isSearchable={false}
                  />
                </div>
                <div className="dropdown-column adjustment-level-dropdown">
                  <SelectDropdown
                    className="dropdown-row"
                    styles={customStyles}
                    onChange={handleOnAdjustmentDropdownChange}
                    options={adjustmentDropdownOptions}
                    value={selectedAdjustmentDropdownOption}
                    isSearchable={false}
                  />
                </div>
              </div>
              <div className="total-adj-table-container">
                <div
                  style={{ marginBottom: "8px" }}
                  className="total-adj-border-row total-adj-top-border-row"
                />
                <div
                  className="total-adj-table-content"
                  style={
                    expandSkuData
                      ? { position: "relative", height: "95%" }
                      : { overflow: "unset" }
                  }
                >
                  { (!driverloading && selectedMerchNumber === "" && selectedAdjustmentCodeValue === "") ? (
                    <DataRow
                      title={"Store Total"}
                      unitQuantity={roundValue(
                        invControlDrivers?.markedOutOfStock?.unitQuantity ??
                          0
                      )}
                      itemCount={roundValue(
                        invControlDrivers?.markedOutOfStock?.itemCount ?? 0)}
                      adjustmentsAmount={toFixedVal(
                        invControlDrivers?.markedOutOfStock?.adjustmentsAmount ??
                          0,
                        "$",
                        "start",
                        0
                      )}
                      isFirstRow={false}
                    />
                  ) : (!driverloading && selectedMerchNumber === "" && selectedAdjustmentCodeValue !== "") ? (
                    <DataRow
                      title={"Store Total"}
                      unitQuantity={roundValue(
                        inventoryControlDepartmentTotalReport?.unitQuantity ??
                          0
                      )}
                      itemCount={roundValue(
                        inventoryControlDepartmentTotalReport?.itemCount ?? 0
                      )}
                      adjustmentsAmount={toFixedVal(
                        inventoryControlDepartmentTotalReport?.adjustmentAmount ??
                          0,
                        "$",
                        "start",
                        0
                      )}
                      isFirstRow={false}
                    />
                  ) : !deptloading && (
                      <DataRow
                        title={selectedMerch.label}
                        unitQuantity={roundValue(
                          inventoryControlDepartmentTotalReport?.unitQuantity ??
                            0
                        )}
                        itemCount={roundValue(
                          inventoryControlDepartmentTotalReport?.itemCount ?? 0
                        )}
                        adjustmentsAmount={toFixedVal(
                          inventoryControlDepartmentTotalReport?.adjustmentAmount ??
                            0,
                          "$",
                          "start",
                          0
                        )}
                        isFirstRow={false}
                      />
                    )
                  }

                  <div
                    style={{ marginBottom: "8px" }}
                    className="total-adj-border-row total-adj-top-border-row"
                  />
                </div>

                <div className="IC-adjustment-longsku-data longsku-data">
                  {!skuError &&
                    !skuLoading &&
                    inventoryControlSkuSalesReport?.length !== 0 && (
                      <SkuSortableMetrice
                        dropdownData={
                          INVENTORY_CONTROL_TOTAL_ADJUSTMENTS_SORT_OPTIONS
                        }
                        sortOptionsLabelData={
                          INVENTORY_CONTROL_TOTAL_ADJUSTMENTS_SORT_DROPDOWN_ARRAY
                        }
                        onApplyClickHandler={onApplyClickHandler}
                      />
                  )}
                  <div className="longsku-row-container">
                    {!skuError &&
                    inventoryControlSkuSalesReport?.length !== 0 ? (
                      !skuLoading &&
                      inventoryControlSkuSalesReport?.map((data, index) => (
                        <ICAdjustmentSkuRow
                          key={index}
                          title={data.itemName}
                          skuNumber={data.itemNumber ?? 0}
                          unitQuantity={data.unitQuantity ?? 0}
                          amount={toFixedVal(
                            data.adjustmentAmount ??
                              0,
                            "$",
                            "start",
                            0
                          ) ?? 0}
                        />
                      ))
                    ) : (
                      <div className="noData-card">
                        <div className="no-data-row">
                          <Typography
                            component="h1"
                            variant="h1"
                            className="label"
                          >
                            There is no data available for this timeframe.
                            Please go back and try a new selection{" "}
                          </Typography>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  employeeInfo: state.auth.employeeInfo,
  dateMatrix: state.salesSummary.currentDateMatrix.value,
});

export default connect(mapStateToProps, undefined)(MarkedOutOfStock);
