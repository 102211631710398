import React from "react";
import ReactDOM from "react-dom";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./config/msalConfig";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles";
import "./index.scss";

import App from "./App";
// import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
// import reportWebVitals from "./reportWebVitals";
import { waitOnCache } from "./graphql";
import { SnackbarProvider } from "notistack";

const msalInstance = new PublicClientApplication(msalConfig);

let theme = createTheme({
  palette: {
    primary: {
      main: "rgba(0, 102, 255, 1)",
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
    // Tell Material-UI what's the font-size on the html element is.
    htmlFontSize: 10,
    fontFamily: ["Franklin Gothic Pro"].join(","),
  },
});
theme = responsiveFontSizes(theme);

waitOnCache.then(() =>
  ReactDOM.render(
    <React.StrictMode>
      <MsalProvider instance={msalInstance}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider maxSnack={1} className="snack">
            <App />
          </SnackbarProvider>
        </ThemeProvider>
      </MsalProvider>
    </React.StrictMode>,
    document.getElementById("root")
  )
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
