import moment from "moment";
import { ALL_ADJUSTMENTS, EAR_PIERCING, BALOONS } from "../common/constants";

export const getDate = (name, type) => {
  if (name === "YESTERDAY") {
    return moment().subtract(1, "day").format("YYYY-MM-DD");
  } else if (name === "LCW") {
    if (type === "fromdate") {
      return moment().subtract(7, "day").format("YYYY-MM-DD");
    } else if (type === "todate") {
      return moment().subtract(1, "day").format("YYYY-MM-DD");
    }
  } else if (name === "WTD") {
    if (type === "fromdate") {
      return moment().subtract(7, "day").format("YYYY-MM-DD");
    } else if (type === "todate") {
      return moment().subtract(1, "day").format("YYYY-MM-DD");
    }
  } else if (name === "MTD") {
    if (type === "fromdate") {
      return moment().subtract(1, "month").format("YYYY-MM-DD");
    } else if (type === "todate") {
      return moment().subtract(1, "day").format("YYYY-MM-DD");
    }
  } else if (name === "QTD") {
    if (type === "fromdate") {
      return moment().subtract(3, "month").format("YYYY-MM-DD");
    } else if (type === "todate") {
      return moment().subtract(1, "day").format("YYYY-MM-DD");
    }
  } else if (name === "YTD") {
    if (type === "fromdate") {
      return moment().subtract(1, "year").format("YYYY-MM-DD");
    } else if (type === "todate") {
      return moment().subtract(1, "day").format("YYYY-MM-DD");
    }
  }
};

export const getDropdownOptions = () => {
  let options = [];
  const yesterday = new Date();
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  yesterday.setDate(yesterday.getDate() - 1);
  let yday = `${
    monthNames[yesterday.getMonth()]
  } ${yesterday.getDate()}, ${yesterday.getFullYear()}`;
  options.push({ value: "YESTERDAY", label: yday });
  options.push({ value: "WTD", label: "Week To Date" });
  options.push({ value: "LCW", label: "Last Complete Week" });
  options.push({ value: "MTD", label: "Month To Date" });
  options.push({ value: "QTD", label: "Quarter To Date" });
  options.push({ value: "YTD", label: "Year To Date" });
  return options;
};

export const getLaborDropdownOptions = () => {
  let options = [];
  options.push({ value: "LCW", label: "Last Complete Week" });
  options.push({ value: "MTD", label: "Month To Date" });
  options.push({ value: "QTD", label: "Quarter To Date" });
  options.push({ value: "YTD", label: "Year To Date" });
  return options;
};

export const getTrendDropdownOptions = () => {
  let options = [];
  options.push({ value: "MTD", label: "Month To Date" });
  options.push({ value: "QTD", label: "Quarter To Date" });
  options.push({ value: "YTD", label: "Year To Date" });
  return options;
};

export const getSalesTrendDropdownOptions = () => {
  let options = [];
  options.push({ value: "LCW", label: "Last Complete Week" });
  options.push({ value: "WTD", label: "Week To Date" });
  options.push({ value: "MTD", label: "Month To Date" });
  options.push({ value: "QTD", label: "Quarter To Date" });
  options.push({ value: "YTD", label: "Year To Date" });
  return options;
};

export const getInventoryTrendDropdownOptions = () => {
  let options = [];
  options.push({ value: "LCW", label: "Last Complete Week" });
  options.push({ value: "MTD", label: "Month To Date" });
  return options;
};

export const round = (num) => {
  var m = Number((Math.abs(num) * 100).toPrecision(15));
  return Math.sign(num) === -1
    ? `(${Math.round(m) / 100}%)`
    : `${(Math.round(m) / 100) * Math.sign(num)}%`;
};

export const roundValue = (e, currencyCode) => {
  if (e) {
    const returnString = Math.round(e).toLocaleString("en");
    if (currencyCode) {
      return e < 0 ? "( $ " + returnString * -1 + ")" : "$ " + returnString;
    } else return e < 0 ? "(" + returnString * -1 + ")" : returnString;
  } else {
    return 0;
  }
};

export const toTitleCase = (str) => {
  return str?.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};
export const getInitials = (nameString) => {
  var fullName = nameString.split(" ");
  var initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
  return initials.toUpperCase();
};

export const toFixedVal = (e, type, pos, digit) => {
  if (e && e !== undefined && e !== null && e !== "") {
    let returnString = "";
    if (digit === 1) {
      returnString = Math.abs(e).toLocaleString(undefined, {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      });
    } else if (digit === 2) {
      returnString = Math.abs(e).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } else {
      returnString = Math.abs(e).toLocaleString(undefined, {
        maximumFractionDigits: 0,
      });
    }
    if (type) {
      if (pos === "start") {
        return e < 0
          ? "( " + type + " " + returnString + ")"
          : "" + type + " " + returnString;
      } else if (pos === "end") {
        return e < 0
          ? "(" + returnString + " " + type + ")"
          : returnString + " " + type;
      } else if (pos === "start_nospace") {
        return e < 0
          ? "( " + type + "" + returnString + ")"
          : "" + type + "" + returnString;
      } else if (pos === "end_nospace") {
        return e < 0
          ? "(" + returnString + "" + type + ")"
          : returnString + "" + type;
      } else {
        return e < 0
          ? "( " + type + " " + returnString + ")"
          : "" + type + " " + returnString;
      }
    } else return e < 0 ? "( " + returnString + ")" : returnString;
  } else {
    return 0;
  }
};

export const nullCheck = (e) => {
  if (e && e !== undefined && e !== "" && e !== null) {
    return e?.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  } else {
    return " ";
  }
};

export const chartValue = (e) => {
  if (e && e !== undefined && e !== null && e !== "") {
    let returnString = Math.abs(e).toFixed(2);
    return e < 0 ? "-" + returnString : returnString;
  } else {
    return 0;
  }
};

export const getRoleDropDownOptions = (staticUserRole) => {
  let roleDropdownArr = [];
  if (staticUserRole.includes("DM")) {
    roleDropdownArr.push({
      label: "District Manager",
      value: "DM",
    });
    roleDropdownArr.push({
      label: "Store Manager",
      value: "SM",
    });
  } else if (staticUserRole.includes("RD")) {
    roleDropdownArr.push({
      label: "Regional Director",
      value: "RD",
    });
    roleDropdownArr.push({
      label: "District Manager",
      value: "DM",
    });
    roleDropdownArr.push({
      label: "Store Manager",
      value: "SM",
    });
  } else if (staticUserRole.includes("SM")) {
    roleDropdownArr.push({
      label: "Store Manager",
      value: "SM",
    });
  }
  return roleDropdownArr;
};

export const getStoreChecker = (n) => {
  return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
};

export const MARKED_OUT_OF_STOCK_OPTIONS = [
  { label: ALL_ADJUSTMENTS, value: ALL_ADJUSTMENTS },
  { label: "05 - MOS – Exp.", value: "05 - MOS – Exp." },
  { label: "06 - MOS – Str. Use", value: "06 - MOS – Str. Use" },
  { label: "07 - MOS – WT Dir.", value: "07 - MOS – WT Dir." },
  { label: "08 - MOS – WOW", value: "08 - MOS – WOW" },
];

export const getServicesMerchDropdownOptions = [
  { label: BALOONS, value: BALOONS },
  { label: EAR_PIERCING, value: EAR_PIERCING },
];
