/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import "./styles.scss";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import DataRow from "./DataRow";
import {
  getDate,
  nullCheck,
  toFixedVal,
  getStoreChecker,
  roundValue,
} from "../../utils/utils";
import { useLazyQuery } from "@apollo/client";
import {
  GET_INVENTORY_CONTROL_DRIVER_TAB_DETAIL,
  GET_DEFAULT_LOCATION,
} from "../../graphql/queries.js";
import ErrorMessage from "../ErrorMessage";
import {
  DATA_FETCH_ERROR,
  ONLOAN_INVALID_STOREID_ERROR,
  DRIVERS,
} from "../../common/constants";
import {
  DAMAGES,
  MARKED_OUT_OF_STOCK,
} from "../../common/constants";
import Damages from "../InventoryControlDriversMenus/Damages";
import MarkedOutOfStock from "../InventoryControlDriversMenus/MarkedOutOfStock";
import BreadCrumbs from "../BreadCrumbs";

const InventoryControlDriversTab = ({ employeeInfo = {}, dateMatrix }) => {
  const [selectedLocation, setSelectedLocation] = React.useState(
    JSON.parse(sessionStorage.getItem("selectedLocation"))
  );
  let selectedStoreId = localStorage.getItem("storeId");

  const [dataRowSelected, setDataRowSelected] = React.useState(false);
  const [selectedDataRow, setselectedDataRow] = React.useState(null);
  const activeStorechecker = getStoreChecker(selectedStoreId);
  const [getDefaultLocation, { error: locationError = {} }] = useLazyQuery(
    GET_DEFAULT_LOCATION,
    {
      context: {
        headers: {
          Authorization: localStorage.getItem("jwtToken"),
        },
      },
      onCompleted: (data) => {
        let { salesSummaryV2 } = data;
        if (selectedLocation.locationName === "NA") {
          setSelectedLocation({
            locationNo: salesSummaryV2?.location?.primaryLocationNumber,
            locationName: salesSummaryV2?.location?.primaryLocationName,
          });
          sessionStorage.setItem(
            "selectedLocation",
            JSON.stringify({
              locationNo: salesSummaryV2?.location?.primaryLocationNumber,
              locationName: salesSummaryV2?.location?.primaryLocationName,
            })
          );
        }
      },
    }
  );

  const userEmail = employeeInfo.adUpn;
  const handleBreadCrumbVisibilityChange = (newState) => {
    setDataRowSelected(newState);
  };
  useEffect(() => {
    if (userEmail && (activeStorechecker === true || selectedStoreId === "")) {
      getDefaultLocation({
        variables: { request: { email: userEmail, storeId: selectedStoreId } },
      });
    }
  }, [userEmail, getDefaultLocation, selectedStoreId, activeStorechecker]);

  const [
    getInventoryControlDriversTabDetails,
    {
      data: { inventoryControl: { invControlDrivers } = {} } = {},
      loading,
      error = {},
    },
  ] = useLazyQuery(GET_INVENTORY_CONTROL_DRIVER_TAB_DETAIL, {
    context: {
      headers: {
        Authorization: localStorage.getItem("jwtToken"),
      },
    },
  });

  useEffect(() => {
    if (
      userEmail &&
      dateMatrix &&
      selectedLocation &&
      selectedLocation?.locationName !== "NA"
    ) {
      getInventoryControlDriversTabDetails({
        variables: {
          request: {
            email: userEmail,
            location: {
              primaryLocationNumber: selectedLocation?.locationNo,
              primaryLocationName: selectedLocation?.locationName,
            },
            toDate: getDate("YESTERDAY", ""),
            matrixType: dateMatrix,
          },
        },
      });
    }
  }, [
    userEmail,
    dateMatrix,
    getInventoryControlDriversTabDetails,
    selectedLocation,
  ]);

  const {
    adjustments = {
      unitQuantity: 0,
      itemCount: 0,
      adjustmentsAmount: 0,
    },
    damages = {
      unitQuantity: 0,
      itemCount: 0,
      adjustmentsAmount: 0,
    },
    markedOutOfStock = {
      unitQuantity: 0,
      itemCount: 0,
      adjustmentsAmount: 0,
    },
    totalAdjustments = {
      unitQuantity: 0,
      itemCount: 0,
      adjustmentsAmount: 0,
    },
  } = invControlDrivers || {};

  

  const handleDataRowClick = (dataRowTitle) => {
    setDataRowSelected(true);
    setselectedDataRow(dataRowTitle);
  };

  const driversMenu = {
    "Marked Out of Stock": <MarkedOutOfStock />,
    "Damages": <Damages/>
  };

  return (
    <div className="inventory-detail-tab">
      {loading && (
        <div className="inventory-detail-loader">
          <CircularProgress color="primary" size={30} />
        </div>
      )}
      {!loading && (
        <>
          {error.message || locationError.message ? (
            <ErrorMessage
              statusCode={
                error?.networkError?.statusCode ||
                locationError?.networkError?.statusCode
              }
              message={DATA_FETCH_ERROR}
            />
          ) : null}
          {activeStorechecker === false && selectedStoreId ? (
            <ErrorMessage message={ONLOAN_INVALID_STOREID_ERROR} />
          ) : null}
          {!dataRowSelected ? (
            <div className="inventory-detail-table-container">
              <div className="table-content">
                {/* <div
                  className="data-row-container"
                  onClick={() => {
                    handleDataRowClick(TOTAL_ADJUSTMENTS);
                  }}
                >
                  <DataRow
                    title={TOTAL_ADJUSTMENTS}
                    isFirstRow={true}
                    unitQuantity={roundValue(totalAdjustments?.unitQuantity)}
                    itemCount={roundValue(totalAdjustments?.itemCount)}
                    adjustmentsAmount={toFixedVal(
                      totalAdjustments?.adjustmentsAmount,
                      "$",
                      "start",
                      1
                    )}
                  />
                </div> */}
                <div className="data-row-container">
                  <DataRow
                    title={DAMAGES}
                    isFirstRow={true}
                    handleDataRowClick={handleDataRowClick}
                    itemCount={roundValue(damages?.itemCount)}
                    unitQuantity={roundValue(damages?.unitQuantity)}
                    adjustmentsAmount={toFixedVal(
                      damages?.adjustmentsAmount,
                      "$",
                      "start",
                      2
                    )}
                  />
                </div>
                <div className="data-row-container">
                  <DataRow
                    title={MARKED_OUT_OF_STOCK}
                    handleDataRowClick={handleDataRowClick}
                    isFirstRow={true}
                    itemCount={roundValue(markedOutOfStock?.itemCount)}
                    unitQuantity={roundValue(markedOutOfStock?.unitQuantity)}
                    adjustmentsAmount={toFixedVal(
                      markedOutOfStock?.adjustmentsAmount,
                      "$",
                      "start",
                      2
                    )}
                  />
                </div>
              </div>
            </div>
          ) : (
            <>
              <BreadCrumbs
                PrimaryValue={DRIVERS}
                SelectedChildRow={selectedDataRow}
                breadCrumbVisibility={dataRowSelected}
                onBreadCrumbVisibilityChange={handleBreadCrumbVisibilityChange}
              ></BreadCrumbs>
              {driversMenu[selectedDataRow]}

            </>
          )}
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    employeeInfo: state.auth.employeeInfo,
    dateMatrix: state.salesSummary.currentDateMatrix.value,
  };
};
export default connect(mapStateToProps, undefined)(InventoryControlDriversTab);
