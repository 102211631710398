import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useMsal, useAccount } from "@azure/msal-react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import MSALogin from "../MSALogin";
import PrivateRoute from "../PrivateRoute/PrivateRoute";
import SalesSummary from "../SalesSummary";
import { privacyPolicy, termsAndCondition } from "../PrivacyTermsAndCondition";
import { setAuthToken } from "../../utils";
import { setLoginUserInfo } from "../../actions/actions";
import { searchUser } from "../../actions/userProfileActions";
import { loginRequest } from "../../config/msalConfig";
import SummaryDetails from "../SummaryDetails";
import { InteractionStatus } from "@azure/msal-browser";
import LogOut from "../LogOut/LogOut";
import LaborDetails from "../LaborDetails";
import ServicesDetails from "../ServicesDetails/ServicesDetails";
import InventoryControlDetails from "../InventoryControlDetails";
import OmniChannelDetails from "../OmniChannelDetails/OmniChannelDetails";

const PageNavigation = ({ setLoginUserInfo, searchUser }) => {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(instance.getActiveAccount() || accounts[0] || {});
  useEffect(() => {
    if (account && inProgress === InteractionStatus.None) {
      instance
        .acquireTokenSilent({
          scopes: loginRequest,
          account: account,
        })
        .then((response) => {
          if (response) {
            const employeeId = (
              account.idTokenClaims.employeeId || ""
            ).padStart(6, "0");
            setAuthToken(response.accessToken);
            searchUser({ username: employeeId });
            setLoginUserInfo(response);
            sessionStorage.setItem(
              "isCorpUser",
              response.idTokenClaims.isCorpUser
            );
          }
        })
        .catch((error) => {
          return instance.acquireTokenRedirect(loginRequest);
        });
    }
  }, [account, instance, setLoginUserInfo, searchUser, inProgress]);
  return (
    <div>
      <BrowserRouter>
        <Route exact path="/login" component={MSALogin} />
        <Route exact path="/" component={MSALogin} />
        <Route exact path="/privacy" component={privacyPolicy} />
        <Route
          exact
          path="/terms-and-conditions"
          component={termsAndCondition}
        />
        <Route exact path="/logout" component={LogOut} />
        <Switch>
          <PrivateRoute exact path="/summary" component={SalesSummary} />
          <PrivateRoute
            exact
            path="/sales-details/:tabId"
            component={SummaryDetails}
          />
          <PrivateRoute
            exact
            path="/services-details/:tabId"
            component={ServicesDetails}
          />
          <PrivateRoute
            exact
            path="/labor-details/:tabId"
            component={LaborDetails}
          />
          <PrivateRoute
            exact
            path="/inventory-details/:tabId"
            component={InventoryControlDetails}
          />
          <PrivateRoute
            exact
            path="/omnichannel-details/:tabId"
            component={OmniChannelDetails}
          />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setLoginUserInfo: (data) => dispatch(setLoginUserInfo(data)),
  searchUser: (userData) => dispatch(searchUser(userData)),
});

export default connect(undefined, mapDispatchToProps)(PageNavigation);
