import React, { useEffect } from "react";
import AppHeader from "../AppHeader";
import { makeStyles } from "@material-ui/core/styles";
import SelectDropdown from "../SelectDropdown";
import { CircularProgress } from "@material-ui/core";
import {
  getLaborDropdownOptions,
  getTrendDropdownOptions,
  toTitleCase,
  getStoreChecker,
} from "../../utils/utils";
import { useLazyQuery } from "@apollo/client";
import { GET_DEFAULT_LOCATION } from "../../graphql/queries";
import ErrorMessage from "../ErrorMessage";
import {
  DATA_FETCH_ERROR,
  ONLOAN_INVALID_STOREID_ERROR,
} from "../../common/constants";
import "./styles.scss";
import BottomNavigator from "../BottomNavigator/BottomNavigator";
import { connect, useSelector } from "react-redux";
import { setCurrentDateMatrix } from "../../actions/actions";
import { LaborTabManager } from "../TabManager";

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    marginTop: "1rem",
  },

  dateRow: {
    fontSize: "1.6rem",
    fontWeight: "500",
    fontFamily: "Franklin Gothic Pro ",
    color: "#353535",
    width: "19rem",
    height: "3rem",
    marginBottom: "1rem",
  },
}));

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: "1.6rem",
    fontFamily: "Franklin Gothic Pro ",
    color: state.isSelected ? "#FFFFFF" : "#353535",
    padding: 7,
  }),
};

const LaborDetails = ({ employeeInfo = {}, currentDateMatrix }) => {
  const classes = useStyles();
  const [options, setOptions] = React.useState([]);
  const [tab, setTab] = React.useState("");
  const currentActiveTab = useSelector(
    (state) => state.currentActiveTab.currentActiveTab
  );
  useEffect(() => {
    setTab(currentActiveTab);
  }, [tab, currentActiveTab]);

  const [selectedDate, setSelectedDates] = React.useState(null);
  const userEmail = employeeInfo.adUpn;
  const [selectedLocation, setSelectedLocation] = React.useState(
    JSON.parse(sessionStorage.getItem("selectedLocation"))
  );
  let selectedStoreId = localStorage.getItem("storeId");
  const activeStorechecker = getStoreChecker(selectedStoreId);
  const [
    getDefaultLocation,
    { error: locationError = {}, loading: locationLoading },
  ] = useLazyQuery(GET_DEFAULT_LOCATION, {
    context: {
      headers: {
        Authorization: localStorage.getItem("jwtToken"),
      },
    },
    onCompleted: (data) => {
      let { salesSummaryV2 } = data;
      if (selectedLocation.locationName === "NA") {
        setSelectedLocation({
          locationNo: salesSummaryV2?.location?.primaryLocationNumber,
          locationName: salesSummaryV2?.location?.primaryLocationName,
        });
        sessionStorage.setItem(
          "selectedLocation",
          JSON.stringify({
            locationNo: salesSummaryV2?.location?.primaryLocationNumber,
            locationName: salesSummaryV2?.location?.primaryLocationName,
          })
        );
      }
    },
  });

  useEffect(() => {
    let options = [];
    if (tab === "Labor") {
      options = getLaborDropdownOptions();
    } else {
      options = getTrendDropdownOptions();
    }
    setOptions(options);
    let dropdownVal = "";
    if (localStorage.hasOwnProperty("selectedDate")) {
      if (
        localStorage.getItem("selectedDate") === "YESTERDAY" ||
        localStorage.getItem("selectedDate") === "WTD" ||
        localStorage.getItem("selectedDate") === "LCW"
      ) {
        if (tab === "Labor") {
          dropdownVal = "LCW";
        } else {
          dropdownVal = "MTD";
        }
      } else {
        dropdownVal = localStorage.getItem("selectedDate");
      }
    } else {
      if (tab === "Labor") {
        dropdownVal = "LCW";
      } else {
        dropdownVal = "MTD";
      }
    }
    let defaultValue = options.filter((obj) => {
      return obj.value === dropdownVal;
    });
    setSelectedDates(defaultValue[0]);
    currentDateMatrix(defaultValue[0]);
  }, [setOptions, setSelectedDates, tab, currentDateMatrix]);

  useEffect(() => {
    if (userEmail && (activeStorechecker === true || selectedStoreId === "")) {
      getDefaultLocation({
        variables: { request: { email: userEmail, storeId: selectedStoreId } },
      });
    }
  }, [userEmail, getDefaultLocation, selectedStoreId, activeStorechecker]);

  const handleDateChange = (e) => {
    setSelectedDates(e);
    currentDateMatrix(e);
    localStorage.setItem("selectedDate", e.value);
  };

  const primarylocation = selectedLocation?.locationName || ""
  const statelocation = primarylocation.split(/\s*[\s,]\s*/).pop();
      let withoutstatelocation = "";
      (primarylocation !== 'NA') ?
      statelocation.length === 2
        ? (withoutstatelocation = primarylocation?.slice(
            0,
            primarylocation.lastIndexOf(",")
          ))
        : (withoutstatelocation = primarylocation)
        : withoutstatelocation = 'NA';

  return (
    <>
      <AppHeader
        headerContent={
          <div className={classes.filterContainer}>
            {locationLoading ? (
              <CircularProgress color="inherit" size={30} />
            ) : (
              <>
               <div className="locationRow">
                  {toTitleCase(withoutstatelocation ?? withoutstatelocation)}
                  {(primarylocation !== 'NA') && (statelocation.length === 2) ? <span>, {statelocation}</span> : ""}
                </div>
              </>
            )}
            {options.length > 0 ? (
              <div className="blue-dropdown">
                <SelectDropdown
                  className={classes.dateRow}
                  styles={customStyles}
                  onChange={handleDateChange}
                  options={options}
                  value={selectedDate}
                  isSearchable={false}
                />
              </div>
            ) : (
              <CircularProgress color="inherit" size={30} />
            )}
          </div>
        }
      >
        {locationError.message ? (
          <ErrorMessage
            statusCode={locationError?.networkError?.statusCode}
            message={DATA_FETCH_ERROR}
          />
        ) : null}
        {activeStorechecker === false && selectedStoreId ? (
          <ErrorMessage message={ONLOAN_INVALID_STOREID_ERROR} />
        ) : null}
        <LaborTabManager />
        <BottomNavigator
          Label="Labor"
          //LeftRoute="/omnichannel-details/bopis"
          LeftRoute="/services-details/drivers"
          DisableLeft={false}
          DisableRight={true}
        />
      </AppHeader>
    </>
  );
};

const mapStateToProps = (state) => ({
  employeeInfo: state.auth.employeeInfo,
});
const mapDispatchToProps = (dispatch) => ({
  currentDateMatrix: (selectedDateMatrix) =>
    dispatch(setCurrentDateMatrix(selectedDateMatrix)),
});
export default connect(mapStateToProps, mapDispatchToProps)(LaborDetails);
