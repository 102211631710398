import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import "./styles.scss";
import salesHierarchyIcon from "../../images/sales-hierarchy-icon.png";
import modalCloseIcon from "../../images/modal-close-icon.png";
import ExpandedLaborSummary from "../ExpandedLaborSummary";
import ExpandedSalesSummary from "../ExpandedSalesSummary";
import ExpandedInventorySummary from "../ExpandedInventorySummary";
import ExpandedServicesSummary from "../ExpandedServicesSummary/ExpandedServicesSummary";
//import ExpandedOmniChannelSummary from "../ExpandedOmniChannelSummary";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "444px",
    border: "2px solid #fff",
    height: "calc(100% - 64px)",
    padding: "10px",
    position: "absolute",
    backgroundColor: "#fff",
    boxShadow:
      "0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)",
  },
}));

const SimpleModal = (props) => {
  // eslint-disable-next-line no-unused-vars
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [modalTitle, setModalTitle] = useState(props.title);
  const [titleInModal, setTitleInModal] = useState(props.titleInModal);

  let ExpandeSummaryTopRowContainer = "";
  let ExpandeSummaryContentContainer = "";

  if (
    props.ExpandedSalesSummaryComponent &&
    props.ExpandedSalesSummaryComponent === "true"
  ) {
    ExpandeSummaryTopRowContainer = "expanded-sales-summary-top-row-container";
    ExpandeSummaryContentContainer = "expanded-sales-summary-content-container";
  }

  if (
    props.ExpandedServiceSummaryComponent &&
    props.ExpandedServiceSummaryComponent === "true"
  ) {
    ExpandeSummaryTopRowContainer =
      "expanded-service-summary-top-row-container";
    ExpandeSummaryContentContainer =
      "expanded-service-summary-content-container";
  }

  if (
    props.ExpandedLaborSummaryComponent &&
    props.ExpandedLaborSummaryComponent === "true"
  ) {
    ExpandeSummaryTopRowContainer = "expanded-labor-summary-top-row-container";
    ExpandeSummaryContentContainer = "expanded-labor-summary-content-container";
  }

  if (
    props.ExpandedOmniChannelComponent &&
    props.ExpandedOmniChannelComponent === "true"
  ) {
    ExpandeSummaryTopRowContainer =
      "expanded-omni-channel-summary-top-row-container";
    ExpandeSummaryContentContainer =
      "expanded-omni-channel-summary-content-container";
  }

  const body = (
    <div className="modal-body-container">
      <div className="modal-header-container">
        <img
          alt="close modal icon"
          className="modal-close-btn-icon"
          src={modalCloseIcon}
          onClick={handleClose}
        />
        <h1 className="modal-heading" id="simple-modal-title" data-testid="simple-modal-title">
          {modalTitle != null ? modalTitle : null}
        </h1>
      </div>
      <div className="modal-content">
        {props.ExpandedSalesSummaryComponent === "true" ? (
          <div className="modal-content-summaryData-title">
            <h1 className="modal-summaryData-title">{titleInModal}</h1>
          </div>
        ) : props.titleInModal ? (
          <div className="modal-content-summaryData-title">
            <h1 className="modal-summaryData-title">{props.titleInModal}</h1>
          </div>
        ) : null}

        {props.modalContentOther ? (
          <div
            className={`modal-content-data-row ${ExpandeSummaryTopRowContainer}`}
          >
            {props.modalContentOther}
          </div>
        ) : null}

        <div
          className={`modal-content-summaryData-container ${ExpandeSummaryContentContainer}`}
        >
          {props.ExpandedLaborSummaryComponent === "true" ? (
            <ExpandedLaborSummary
              primaryLocationNumber={props.primaryLocationNumber}
              primaryLocationName={props.primaryLocationName}
              selectedDateMatrix={props.selectedDateMatrix}
              getModalTitle={setModalTitle}
            />
          ) : null}
          {props.ExpandedSalesSummaryComponent === "true" ? (
            <ExpandedSalesSummary
              primaryLocationNumber={props.primaryLocationNumber}
              primaryLocationName={props.primaryLocationName}
              selectedDateMatrix={props.selectedDateMatrix}
              getModalTitle={setModalTitle}
              getTitleInModal={setTitleInModal}
            />
          ) : null}
          {props.ExpandedInventorySummaryComponent === "true" ? (
            <ExpandedInventorySummary
              primaryLocationNumber={props.primaryLocationNumber}
              primaryLocationName={props.primaryLocationName}
              selectedDateMatrix={props.selectedDateMatrix}
              getModalTitle={setModalTitle}
              getTitleInModal={setTitleInModal}
            />
          ) : null}
          {props.ExpandedServiceSummaryComponent === "true" ? (
            <ExpandedServicesSummary
              primaryLocationNumber={props.primaryLocationNumber}
              primaryLocationName={props.primaryLocationName}
              selectedDateMatrix={props.selectedDateMatrix}
              getModalTitle={setModalTitle}
              getTitleInModal={setTitleInModal}
            />
          ) : null}
          {/* {props.ExpandedOmniChannelComponent === "true" ? (
            <ExpandedOmniChannelSummary
              primaryLocationNumber={props.primaryLocationNumber}
              primaryLocationName={props.primaryLocationName}
              selectedDateMatrix={props.selectedDateMatrix}
              getModalTitle={setModalTitle}
              getTitleInModal={setTitleInModal}
            />
          ) : null} */}
        </div>
      </div>
    </div>
  );

  return (
    <div className="modal-container">
      <button className="modal-open-btn" type="button" onClick={handleOpen}>
        <img
          alt="open modal"
          className="modal-open-btn-img"
          src={salesHierarchyIcon}
        />
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
};

export default SimpleModal;
