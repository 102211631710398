import React, { useState } from "react";
import Check from "@material-ui/icons/Check";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import Box from "@material-ui/core/Box";
import { ASCENDING, DESCENDING } from "../../common/constants";

const SortableMetrics = ({ sortOptions, onApplyClickHandler, updateMenuItems }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [menuItems, setMenuItems] = useState(sortOptions || []);
  const toggleSortMenu = () => {
    setMenuOpen((prevIsMwnuOpen) => !prevIsMwnuOpen);
  };
  const toggleMenuItem = (selectedMenuItem, index) => () => {
    const updatedMenuItems = menuItems.map((mi) => {
      if (mi.label !== selectedMenuItem.label) {
        if (
          ([ASCENDING, DESCENDING].includes(selectedMenuItem.label) &&
            ![ASCENDING, DESCENDING].includes(mi.label)) ||
          (![ASCENDING, DESCENDING].includes(selectedMenuItem.label) &&
            [ASCENDING, DESCENDING].includes(mi.label))
        ) {
          return mi;
        }
        return { ...mi, isChecked: false };
      }
      return { ...mi, isChecked: true };
      
    });
    setMenuItems(updatedMenuItems);
  };
  const applyFilter = () => {
    setMenuOpen(false);
    const checkedMenuItems = menuItems.filter((mi) => mi.isChecked);
    updateMenuItems(menuItems);
    onApplyClickHandler(checkedMenuItems);
  };
  return (
    <div data-testid="sortable-metrics" className="sort-container">
      <div data-testid="sort-box" className="sort-box" onClick={toggleSortMenu}>
        Sort
        <KeyboardArrowDown color="primary" fontSize="large" variant="filled" />
      </div>
      {isMenuOpen && (
        <div data-testid="sort-menu-container" className="sort-menu-container">
          <div className="menu-item sort-by-header px-20 py-5">
            <span>Sort By</span>
          </div>
          {menuItems.map((mi, index) => (
            <div
              className={`menu-item px-20 py-10 ${
                mi.label === ASCENDING ? "ascending-menu-item" : ""
              }`}
              key={mi.label}
              onClick={toggleMenuItem(mi, index)}
            >
              <span>{mi.label}</span>
              {mi.isChecked && (
                <Check color="primary" fontSize="medium" variant="filled" />
              )}
            </div>
          ))}
          <Box
            data-testid="sort-box-apply"
            color="primary.main"
            className="menu-item apply-button px-20 py-5"
            onClick={applyFilter}
          >
            <span>Apply</span>
          </Box>
        </div>
      )}
    </div>
  );
};

export default SortableMetrics;
